<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="7"
                xl="11"
                lg="10"
                md="9"
                sm="6"
            >
                <h3>{{ $t('dashboard') }}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                xl="10"
                lg="10"
                md="8"
                sm="6"
            >
                <v-combobox
                    :label="$t('status')"
                    v-model="statusSelected"
                    :items="listStatus"
                    item-text="descricao"
                    item-value="idStatus"
                    dense
                    outlined
                    @change="getRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                xl="2"
                lg="2"
                md="4"
                sm="6"
            >
                <v-combobox
                    :label="$t('ano')"
                    v-model="anoSelected"
                    :items="listAno"
                    item-text="descricao"
                    item-value="id"
                    dense
                    outlined
                    @change="getRegisters()"
                ></v-combobox>
            </v-col>
        </v-row>            
        
        <v-row>
            <v-col
                v-for="(item, index) in listDashboard" :key="index"
                cols="12"
                xl="3"
                lg="3"
                md="4"
                sm="12"
            >
                <v-card-text class="">
                    <v-card class="mx-auto">
                        <v-card-title class="title">
                            {{ item.tipoDashboardDescricao }}
                        </v-card-title>
                        <v-card-text class="content">
                            {{ item.qtdeTotal }}
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-col>
        </v-row>    

        <br />
        
        <v-tabs
            v-model="tabGrafico"
            fixed-tabs
        >
            <v-tab key="1" @click="getRegisters">
                {{ $t('graficoPorMarca') }}
            </v-tab>
            <v-tab key="2" @click="getRegisters">
                {{ $t('graficoPorStatus') }}
            </v-tab>
        </v-tabs>
        
        <v-tabs-items v-model="tabGrafico">

            <!-- GRÁFICO POR MARCA -->
            <v-tab-item key="1">
                <br />

                <v-tabs vertical fixed-tabs>
                    <v-tab @click="getRegisters">
                        {{ $t('qtde') }}
                    </v-tab>
                    <v-tab @click="getRegisters">
                        {{ $t('valor') }}
                    </v-tab>
        
                    <!-- POR QTDE -->
                    <v-tab-item key="1">
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <BarChart
                                    ref="chartMarcaQtde"
                                    :chartId="'dashboardVendasMarcaQtde'"
                                    :chartData="dashboardVendasMarcaQtde"
                                />
                            </v-col>
                        </v-row>
                        
                    </v-tab-item>

                    <!-- POR VALOR -->
                    <v-tab-item key="2">

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <BarChart
                                    ref="chartMarcaValor"
                                    :chartId="'dashboardVendasMarcaValor'"
                                    :chartData="dashboardVendasMarcaValor"
                                />
                            </v-col>
                        </v-row>

                    </v-tab-item>
                </v-tabs>

                
            </v-tab-item>

            <!-- POR STATUS -->
            <v-tab-item key="2">
                <br />

                <v-tabs vertical fixed-tabs>
                    <v-tab @click="getRegisters">
                        {{ $t('qtde') }}
                    </v-tab>
                    <v-tab @click="getRegisters">
                        {{ $t('valor') }}
                    </v-tab>

                    <!-- POR QTDE -->
                    <v-tab-item key="1">

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <BarChart
                                    ref="chartStatusQtde"
                                    :chartId="'dashboardVendasStatusQtde'"
                                    :chartData="dashboardVendasStatusQtde"
                                />
                            </v-col>
                        </v-row>
                        
                    </v-tab-item>

                    <!-- POR VALOR -->
                    <v-tab-item key="2">

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <BarChart
                                    ref="chartStatusValor"
                                    :chartId="'dashboardVendasStatusValor'"
                                    :chartData="dashboardVendasStatusValor"
                                />
                            </v-col>
                        </v-row>

                    </v-tab-item>
                </v-tabs>

            </v-tab-item>

        </v-tabs-items>
        
    </v-container>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import BarChart from '@/components/Graficos/Bar.vue';
    import * as moment from 'moment';
    
    export default ({

        components: {
            BarChart
        },

        mixins: [Helpers],

        data: () => ({

            listAno: [],
            anoSelected: { 
                id: 0, 
                descricao: "" 
            },            

            listStatus: [],
            listLabels: [],
            statusSelected: null,

            tabGrafico: null,
            tabMarca: 0,
            tabStatus: 0,

            listDashboard: [],

            listMeses: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],

            dashboardVendasMarcaQtde: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},

            dashboardVendasMarcaValor: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},

            dashboardVendasStatusQtde: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},
            dashboardVendasStatusValor: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            isUsuarioInterno() {
                return this.userLoggedGetters.usuarioInterno == 1;
            },
        },

        methods: {

            generateColor(){
                return `#${Math.floor(Math.random()*16777215).toString(16)}`;
            },

            backgroundColors(data) {
                return data.map(() => this.generateColor())
            },

            async carregaAnos() {
                let anoAtual = moment().clone().startOf('month').format('YYYY');

                this.listAno.push({id: 1, descricao: anoAtual}); anoAtual -= 1;
                this.listAno.push({id: 2, descricao: anoAtual}); anoAtual -= 1;
                this.listAno.push({id: 3, descricao: anoAtual}); anoAtual -= 1;
                this.listAno.push({id: 4, descricao: anoAtual}); anoAtual -= 1;
                this.listAno.push({id: 5, descricao: anoAtual}); anoAtual -= 1;

                this.anoSelected = this.listAno[0];
            },

            async getFilters() {

                let ano = 0;
                let status = 0;

                if (this.anoSelected != null && this.anoSelected != undefined) {
                    ano = this.anoSelected.descricao
                }

                if (this.statusSelected != null && this.statusSelected != undefined) {
                    status = this.statusSelected.idStatus
                }

                return {
                    ano,
                    status
                }
            },

            async getRegisters() {

                this.showLoading();

                await this.montaGraficoPorMarca();
                await this.montaGraficoPorStatus();

                this.hideLoading();
            },

            async montaGraficoPorMarca() {

                const filters = await this.getFilters();
                const tipoGrafico = 1; //POR MARCA;

                this.listDashboard = await this.$store.dispatch("dashboardModule/DashboardPrincipal", { ano: filters.ano, status: filters.status });
                const listDashboardGrafico = await this.$store.dispatch("dashboardModule/DashboardPrincipalGrafico", { ano: filters.ano, status: filters.status, tipoGrafico });

                const listUniqueId = [...new Set(listDashboardGrafico.map((item) => item.idMarca))];
                
                this.dashboardVendasMarcaQtde.datasets = [];
                this.dashboardVendasMarcaValor.datasets = [];

                listUniqueId.forEach(item => {

                    let dataQtde = [];
                    let dataValor = [];
                    let nomeMarca = "";
                    let corGrafico = "";
                    listDashboardGrafico.filter(dash => dash.idMarca == item).forEach (dash => {
                        dataQtde.push(dash.totalMesQtde);
                        dataValor.push(dash.totalMesValor);
                        nomeMarca = dash.descricaoMarca;
                        corGrafico = dash.corGrafico;
                    });

                    this.dashboardVendasMarcaQtde.datasets.push({
                        label: nomeMarca,
                        backgroundColor: corGrafico ?? this.generateColor(),
                        data: dataQtde
                    });

                    this.dashboardVendasMarcaValor.datasets.push({
                        label: nomeMarca,
                        backgroundColor: corGrafico ?? this.generateColor(),
                        data: dataValor
                    });
                });

                if (this.$refs.chartMarcaQtde) {
                    this.$refs.chartMarcaQtde.$refs.chart._data._chart.config._config.data.labels = this.$t('listMeses');
                    this.$refs.chartMarcaQtde.$refs.chart._data._chart.config._config.data.datasets = this.dashboardVendasMarcaQtde.datasets;
                    this.$refs.chartMarcaQtde.$refs.chart._data._chart.update("none");
                }

                if (this.$refs.chartMarcaValor) {
                    this.$refs.chartMarcaValor.$refs.chart._data._chart.config._config.data.labels = this.$t('listMeses');
                    this.$refs.chartMarcaValor.$refs.chart._data._chart.config._config.data.datasets = this.dashboardVendasMarcaValor.datasets;
                    this.$refs.chartMarcaValor.$refs.chart._data._chart.update("none");
                }
            },

            async montaGraficoPorStatus() {

                const filters = await this.getFilters();
                const tipoGrafico = 2; //POR STATUS;

                this.listDashboard = await this.$store.dispatch("dashboardModule/DashboardPrincipal", { ano: filters.ano, status: filters.status });
                const listDashboardGrafico = await this.$store.dispatch("dashboardModule/DashboardPrincipalGrafico", { ano: filters.ano, status: filters.status, tipoGrafico });

                let listStatusFromDash = [];

                if (this.isUsuarioInterno == true) {
                    listStatusFromDash = [...new Set(listDashboardGrafico.map((item) => { return { idStatus: item.status, descricao: item.statusDescricao } }))];
                }
                else {
                    listStatusFromDash = [...new Set(listDashboardGrafico.map((item) => { return { idStatus: item.status, descricao: item.descricaoStatusCliente } }))];
                }

                this.listLabels = [...new Set(listStatusFromDash.map((item) => item.descricao ))];

                if (this.listStatus.length <= 0) 
                {
                    this.listStatus = [...listStatusFromDash];
                }
                
                this.dashboardVendasStatusQtde.datasets = [];
                this.dashboardVendasStatusValor.datasets = [];

                let listDataQtde = [];
                let listDataValor = [];

                listDashboardGrafico.forEach (dash => {
                    listDataQtde.push(dash.totalQtde)
                    listDataValor.push(dash.totalValor)
                });

                this.dashboardVendasStatusQtde.datasets.push({
                    label: this.$t('qtde'),
                    backgroundColor: "#64BE49",
                    data: listDataQtde
                });

                this.dashboardVendasStatusValor.datasets.push({
                    label: this.$t('valor'),
                    backgroundColor: "#7da6ce",
                    data: listDataValor
                });
                    
                if (this.$refs.chartStatusQtde) {
                    this.$refs.chartStatusQtde.$refs.chart._data._chart.config._config.data.labels = this.listLabels;
                    this.$refs.chartStatusQtde.$refs.chart._data._chart.config._config.data.datasets = this.dashboardVendasStatusQtde.datasets;
                    this.$refs.chartStatusQtde.$refs.chart._data._chart.update("none");
                }
                    
                if (this.$refs.chartStatusValor) {
                    this.$refs.chartStatusValor.$refs.chart._data._chart.config._config.data.labels = this.listLabels;
                    this.$refs.chartStatusValor.$refs.chart._data._chart.config._config.data.datasets = this.dashboardVendasStatusValor.datasets;
                    this.$refs.chartStatusValor.$refs.chart._data._chart.update("none");
                }

                if (this.statusSelected == null || this.statusSelected == undefined) {
                    const todosStatus = { idStatus: 0, descricao: this.$t('todosStatus') };
                    this.listStatus.splice(0, 0, todosStatus);
                    this.statusSelected = todosStatus;
                }
            }
        },

        async mounted() {

            await this.carregaAnos();
            await this.getRegisters();
            
            this.tabGrafico = null;
            this.$forceUpdate();
        }
    })

</script>

<style scoped>
    .title {
        display: block !important;
        font-size: 13px !important;
        text-align: center !important;
        border-bottom: solid 1px var(--color__cinza);
    }

    .content {
        font-size: 30px !important;
        font-weight: bold !important;
        text-align: center !important;
        margin-top: 20px;
    }

</style>